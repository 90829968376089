<template>
    <div id="profile">
        <div class="mcontainer">
            <div class="d-flex justify-content-end mb-3">
                <button v-if="!datauser.is_friend" @click="addFriend" class="btn btn-primary">
                    <span v-if="friendLoading"><b-spinner small label="Loading..." /></span>
                    Add Friend
                </button>
                <button v-if="datauser.is_friend && !datauser.is_friend_confirmed" class="btn btn-primary">
                    <span v-if="friendLoading"><b-spinner small label="Loading..." /></span>
                    Friend Request Sent
                </button>
                <button v-if="datauser.is_friend && datauser.is_friend_confirmed" @click="removeFriend" class="btn btn-primary">
                    <span v-if="friendLoading"><b-spinner small label="Loading..." /></span>
                    Remove Friend
                </button>
            </div>
            <div class="card">
                <CV :user="datauser" :items="dataItemUser" :type="'public'" />
            </div>
        </div>
    </div>
</template>
<script>
    import CV from '@/components/CuriculumVitae'
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'Account',
        components: {
            CV
        },
        computed: {
            ...mapState('auth', {
                datauser: state => state.selected.user,
                loading: state => state.selected.loading,
                user: state => state.user
            })
        },
        data () {
            return {
                dataItemUser: {
                    skills: [],
                    achievement: [],
                    certification: [],
                    education: [],
                    experience: []
                },
                friendLoading: false
            }
        },
        methods: {
            ...mapActions('accountItem', {
                _fetchItem: 'fetch'
            }),
            ...mapActions('auth', {
                _fetchUser: 'show',
                _addFriend: 'addFriend',
                _removeFriend: 'removeFriend'
            }),
            fetchUser () {
                this._fetchItem(this.$route.params.code).then((result) => {
                    this.dataItemUser = result
                })
                this._fetchUser(this.$route.params.code)
            },
            addFriend () {
                this.friendLoading = true
                this._addFriend(this.datauser.id)
                    .then(res => {
                        this.fetchUser()
                        this.$swal(
                            'Added!',
                            'Request teman telah dikirimkan ke ' + this.datauser.fullname,
                            'success'
                        )
                    })
                    .finally(() => {
                        this.friendLoading = false
                    })
            },
            removeFriend () {
                this.friendLoading = true
                this._removeFriend(this.datauser.id)
                    .then(res => {
                        this.fetchUser()
                        this.$swal(
                            'Removed!',
                            this.datauser.fullname + ' telah dihapus dari teman.',
                            'success'
                        )
                    })
                    .finally(() => {
                        this.friendLoading = false
                    })
            }
        },
        created () {
            this.fetchUser()
        }
    }
</script>
