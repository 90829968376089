<template>
    <div>
        <div class="row cv-background" v-if="user" :style="'background-image: url(' + user.cv_bg + ');'">
            <div class="col-md-4" style="min-height: 1122px;">
                <div class="sidebar-cv" :style="'background: ' + user.cv_color">
                    <div class="header-cv">
                        <img :src="user.images ? user.images : $placeholder2" alt="images" class="profile-picture">
                        <h3 class="name-cv">{{ user.fullname }}</h3>
                        <p>{{ user.faculity }}</p>
                        <table>
                            <tr v-if="user.no_induk !== '' && user.no_induk !== null  && hide_nrp === 0">
                                <td><Icon icon="bx:bx-id-card" /></td>
                                <td><p>{{ user.no_induk }}</p></td>
                            </tr>
                        </table>
                    </div>
                    <h5 class="title-cv">ABOUT</h5>
                    <table>
                        <tr v-if="user.ttl !== '' && user.ttl !== null  && hide_ttl === 0">
                            <td><Icon icon="ic:round-date-range" /></td>
                            <td><p>{{ user.ttl }}</p></td>
                        </tr>
                    </table>
                    <p style="white-space: pre-line;">{{ user.about }}</p>
                    <br><br>
                    <h5 class="title-cv">CONTACT</h5>
                    <table>
                        <tr v-if="user.phone !== '' && user.phone !== null && hide_telp === 0">
                            <td><Icon icon="bx:bxs-phone-call" /></td>
                            <td><p>{{ user.phone }}</p></td>
                        </tr>
                        <tr v-if="user.email !== '' && user.email !== null">
                            <td><Icon icon="ic:baseline-email" /></td>
                            <td><p>{{ user.email }}</p></td>
                        </tr>
                        <tr v-if="user.ig_username !== '' && user.ig_username !== null && hide_ig === 0">
                            <td><Icon icon="ph:instagram-logo-fill" /></td>
                            <td><p>{{ user.ig_username }}</p></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-md-8">
                <div class="container-cv">
                    <div v-if="items.skills.length > 0">
                        <h5 class="title-cv">Skills</h5>
                        <table>
                            <tr v-for="item in items.skills" :key="item.id">
                                <td v-if="item.images !== ''"><img :src="item.images" alt="PLACEHOLDER" class="icon-cv"></td>
                                <td :colspan="item.images === '' ? 2 : 0">
                                    <p style="margin-top: 0px;white-space: pre-line"><strong>{{ item.title }}</strong><br>{{ item.description }}</p>
                                </td>
                            </tr>
                        </table>
                        <br>
                    </div>
                    <div v-if="items.education.length > 0">
                        <h5 class="title-cv">Education</h5>
                        <table>
                            <tr v-for="item in items.education" :key="item.id">
                                <td v-if="item.images !== ''"><img :src="item.images" alt="PLACEHOLDER" class="icon-cv"></td>
                                <td :colspan="item.images === '' ? 2 : 0">
                                    <span class="btn-edit-outline" @click.prevent="editItem(item, 'Education')">Edit</span>
                                    <span class="btn-edit-outline btn-delete" @click.prevent="deleteItem(item.id)"><Icon icon="bi:trash" /></span>
                                    <strong>{{ item.title }}</strong>
                                    <p style="white-space: pre-line;">
                                        <b>{{ item.subtitle }}</b> <span class="opacity-75">{{ formatDateThreeMonth(item.start_date) }} - {{ formatDateThreeMonth(item.end_date) !== '' ? formatDateThreeMonth(item.end_date) : 'Now' }}</span><br>
                                        {{ item.description }}
                                    </p>
                                </td>
                            </tr>
                        </table>
                        <br>
                    </div>
                    <div v-if="items.certification.length > 0">
                        <h5 class="title-cv">License and Certification</h5>
                        <table>
                            <tr v-for="item in items.certification" :key="item.id">
                                <td v-if="item.images !== ''"><img :src="item.images" alt="PLACEHOLDER" class="icon-cv"></td>
                                <td :colspan="item.images === '' ? 2 : 0">
                                    <span class="btn-edit-outline" @click.prevent="editItem(item, 'Certification')">Edit</span>
                                    <span class="btn-edit-outline btn-delete" @click.prevent="deleteItem(item.id)"><Icon icon="bi:trash" /></span>
                                    <strong>{{ item.title }}</strong>
                                    <p style="white-space: pre-line;">
                                        <b>{{ item.subtitle }}</b> <span class="opacity-75">Diterbitkan {{ formatDateMonth(item.start_date) }}</span><br>
                                        {{ item.description }}
                                    </p>
                                </td>
                            </tr>
                        </table>
                        <br>
                    </div>
                    <div v-if="items.achievement.length > 0">
                        <h5 class="title-cv">Achievement</h5>
                        <table>
                            <tr v-for="item in items.achievement" :key="item.id">
                                <td v-if="item.images !== ''"><img :src="item.images" alt="PLACEHOLDER" class="icon-cv"></td>
                                <td :colspan="item.images === '' ? 2 : 0">
                                    <span class="btn-edit-outline" @click.prevent="editItem(item, 'Achievement')">Edit</span>
                                    <span class="btn-edit-outline btn-delete" @click.prevent="deleteItem(item.id)"><Icon icon="bi:trash" /></span>
                                    <strong>{{ item.title }}</strong>
                                    <p style="white-space: pre-line;">
                                        <b>{{ item.subtitle }}</b><br>
                                        {{ item.description }}
                                    </p>
                                </td>
                            </tr>
                        </table>
                        <br>
                    </div>
                    <div v-if="items.experience.length > 0">
                        <h5 class="title-cv">Experience</h5>
                        <table>
                            <tr v-for="item in items.experience" :key="item.id">
                                <td v-if="item.images !== ''"><img :src="item.images" alt="PLACEHOLDER" class="icon-cv"></td>
                                <td :colspan="item.images === '' ? 2 : 0">
                                    <span class="btn-edit-outline" @click.prevent="editItem(item, 'Experience')">Edit</span>
                                    <span class="btn-edit-outline btn-delete" @click.prevent="deleteItem(item.id)"><Icon icon="bi:trash" /></span>
                                    <strong>{{ item.title }}</strong>
                                    <p style="white-space: pre-line;">
                                        <b>{{ item.subtitle }}</b> <span class="opacity-75">{{ formatDateThreeMonth(item.start_date) }} - {{ formatDateThreeMonth(item.end_date) !== '' ? formatDateThreeMonth(item.end_date) : 'Now' }}</span><br>
                                        {{ item.description }}
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'

    export default {
        name: 'curiculum_vitae',
        props: {
            items: {
                type: Object,
                default: () => ({})
            },
            user: {
                type: Object,
                default: () => {}
            },
            type: {
                type: String,
                default: ''
            }
        },
        components: {
            Icon
        },
        data () {
            return {
                hide_ig: 1,
                hide_telp: 1,
                hide_nrp: 1,
                hide_ttl: 1
            }
        },
        watch: {
            'user' () {
                this.check_hide()
            }
        },
        methods: {
            check_hide () {
                if (this.type === 'public' && this.user.show_ig === 1) {
                    this.hide_ig = 0
                }
                if (this.type === 'public' && this.user.show_telp === 1) {
                    this.hide_telp = 0
                }
                if (this.type === 'public' && this.user.show_nrp === 1) {
                    this.hide_nrp = 0
                }
                if (this.type === 'public' && this.user.show_ttl === 1) {
                    this.hide_ttl = 0
                }
            }
        },
        created () {
            if (this.type !== 'public') {
                this.hide_ig = 0
                this.hide_telp = 0
                this.hide_nrp = 0
                this.hide_ttl = 0
            }
        }
    }
</script>
